import React, { useState, useEffect } from 'react';
import { getProductUrl } from 'helpers/url';
import { useIntl } from 'gatsby-plugin-react-intl';

import {
    Spacing,
    SharedStyles,
    Container,
    Filter,
    ProductGrid,
} from '../kenra-storybook/index';
import get from 'lodash.get';

const showCreatives = true;

const { StPageImageTitleBar } = SharedStyles;
const Filters = {
    All: 'all',
    Permanent: 'Kenra Color Permanent',
    Demi: 'Kenra Color Demi',
    Creatives: 'Creatives',
    Semi: 'Semi Permanent',
    Blonde: 'Simply Blonde',
    StudioStylist: 'Studio Stylist Express',
    SupportTools: 'Support Tools',
    RapidToners: 'Rapid Toners',
    Lighteners: 'Lighteners',
};

const ShopifyCollectionHandles = {
    Permanent: 'kenra-color-permanent',
    Demi: 'kenra-color-demi-permanent',
    Creatives: 'kenra-color-creatives',
    Semi: 'kenra-color-semi',
    Blonde: 'kenra-color-simply-blonde',
    StudioStylist: 'kenra-color-studio-stylist-express',
    SupportTools: 'kenra-color-support-products',
    RapidToners: 'kenra-color-rapid-toners',
    Lighteners: 'kenra-color-lighteners',
    AllProducts: 'kenra-color-all',
};

export const UrlFiltersEnum = {
    PERMANENT: 'permanent',
    DEMI: 'demi-permanent',
    CREATIVES: 'creatives',
    SEMI: 'semi-permanent',
    BLONDE: 'simply-blonde',
    STUDIO_STYLIST: 'studio-stylist',
    SUPPORT_TOOLS: 'support-tools',
    RAPID_TONERS: 'rapid-toners',
    LIGHTENERS: 'lighteners',
};

function getColorCategory(shopifyProductTags, intl) {
    if (shopifyProductTags.includes(Filters.Permanent)) {
        return intl.formatMessage({ id: 'color-filter-permanent' });
    } else if (shopifyProductTags.includes(Filters.Demi)) {
        return intl.formatMessage({ id: 'color-filter-demi-permanent' });
        /*  } else if (shopifyProductTags.includes(Filters.Semi)) {
    return intl.formatMessage({ id: 'color-filter-permanent' });*/
    } else if (shopifyProductTags.includes(Filters.Blonde)) {
        return intl.formatMessage({ id: 'color-filter-simply-blonde' });
    } else if (shopifyProductTags.includes(Filters.StudioStylist)) {
        return intl.formatMessage({ id: 'color-filter-studio-stylist' });
    } else if (shopifyProductTags.includes(Filters.RapidToners)) {
        return intl.formatMessage({ id: 'color-filter-rapid-toners' });
    } else if (shopifyProductTags.includes(Filters.Lighteners)) {
        return intl.formatMessage({ id: 'color-filter-lighteners' });
    } else if (shopifyProductTags.includes(Filters.SupportTools)) {
        return intl.formatMessage({ id: 'color-filter-support-tools' });
    } else if (shopifyProductTags.includes(Filters.Creatives)) {
        return intl.formatMessage({ id: 'color-filter-creatives' });
    } else {
        return null;
    }
}

function getColorSwatch(color, colors, intl) {
    let handle = get(color, 'handle');
    let subTitle;
    let isLight = false;
    let bg = get(color, 'images[1].localFile.publicURL'); // get(color, 'hairImage.localFile.publicURL')
    let img = get(color, 'images[0].localFile.thumbnail.resize.src');
    let variants = get(color, 'variants');

    // Handle possibility of undefined value - may get this due to .gif file
    if (img === undefined) {
        img = get(color, 'images[0].localFile.publicURL');
    }

    // check if there is a product in contentful to override bg or subtitle with
    let contentfulProduct = colors.find(
        p => handle === get(p, 'product.handle')
    );
    if (contentfulProduct) {
        const overrideBg = get(
            contentfulProduct,
            'hairImage.localFile.publicURL'
        );
        if (overrideBg) {
            bg = overrideBg;
        }
        subTitle = contentfulProduct.title;
        isLight = get(contentfulProduct, 'hairImageIsLight');
    }

    return {
        type: getColorCategory(get(color, 'tags'), intl),
        title: get(color, 'title'),
        bg,
        img,
        subTitle,
        href: getProductUrl(handle),
        isLight,
        sku: variants[0].sku,
    };
}

function getContentfulCategory(contentfulHandle, contentfulCategories, intl) {
    let category = null;
    for (var i = 0; i < contentfulCategories.length; i++) {
        const handleForThisCategory = get(
            contentfulCategories[i],
            'shopifyCollection.handle'
        );
        if (contentfulHandle === handleForThisCategory) {
            category = {
                title: get(contentfulCategories[i], 'title'),
                bg: get(
                    contentfulCategories[i],
                    'backgroundImage.localFile.publicURL'
                ),
                subTitle: get(contentfulCategories[i], 'subTitle'),
                linkUrl: get(contentfulCategories[i], 'buttonUrl'),
                buttonText:
                    get(contentfulCategories[i], 'buttonText') ||
                    intl.formatMessage({ id: 'kenra-shop-now' }),
                isLight: get(contentfulCategories[i], 'isBackgroundImageLight'),
                slides: get(contentfulCategories[i], 'slides'),
            };
            break;
        }
    }
    return category;
}

export default function ColorList({
    header,
    colors,
    demi,
    permanent,
    blonde,
    stylist,
    rapidToners,
    lighteners,
    creatives,
    supportTools,
    contentfulCategories,
    auth,
}) {
    let intl = useIntl();
    const [currentFilter, setCurrentFilter] = useState();

    useEffect(() => {
        const url = typeof window !== 'undefined' ? window.location.href : '';
        if (url.split('?filter=').length > 1) {
            const type = url.split('?filter=')[1];
            let filterType = '';
            switch (type) {
                case UrlFiltersEnum.PERMANENT:
                    filterType = Filters.Permanent;
                    break;
                case UrlFiltersEnum.DEMI:
                    filterType = Filters.Demi;
                    break;
                case UrlFiltersEnum.SEMI:
                    filterType = Filters.Semi;
                    break;
                case UrlFiltersEnum.BLONDE:
                    filterType = Filters.Blonde;
                    break;
                case UrlFiltersEnum.STUDIO_STYLIST:
                    filterType = Filters.StudioStylist;
                    break;
                case UrlFiltersEnum.RAPID_TONERS:
                    filterType = Filters.RapidToners;
                    break;
                case UrlFiltersEnum.LIGHTENERS:
                    filterType = Filters.Lighteners;
                    break;
                case UrlFiltersEnum.CREATIVES:
                    filterType = Filters.Creatives;
                    break;
                default:
                    filterType = Filters.All;
            }
            setCurrentFilter(filterType);
        } else {
            setCurrentFilter(Filters.All);
        }
    }, []);

    let permanentProducts = [];
    permanent &&
        permanent.products &&
        permanent.products.length > 0 &&
        permanent.products.forEach(p => {
            permanentProducts.push(p);
        });
    let demiProducts = [];
    demi &&
        demi.products &&
        demi.products.length > 0 &&
        demi.products.forEach(p => {
            demiProducts.push(p);
        });
    let blondeProducts = [];
    blonde &&
        blonde.products &&
        blonde.products.length > 0 &&
        blonde.products.forEach(p => {
            blondeProducts.push(p);
        });
    let stylistProducts = [];
    stylist &&
        stylist.products &&
        stylist.products.length > 0 &&
        stylist.products.forEach(p => {
            stylistProducts.push(p);
        });

    let rapidTonerProducts = [];
    rapidToners &&
        rapidToners.products &&
        rapidToners.products.length > 0 &&
        rapidToners.products.forEach(p => {
            rapidTonerProducts.push(p);
        });

    let lightenersProducts = [];
    lighteners &&
        lighteners.products &&
        lighteners.products.length > 0 &&
        lighteners.products.forEach(p => {
            lightenersProducts.push(p);
        });

    let creativesProducts = [];
    creatives &&
        creatives.products &&
        creatives.products.length > 0 &&
        creatives.products.forEach(p => {
            creativesProducts.push(p);
        });

    let supportToolsProducts = [];
    supportTools &&
        supportTools.products &&
        supportTools.products.length > 0 &&
        supportTools.products.forEach(p => {
            supportToolsProducts.push(p);
        });

    let allProducts = [];
    permanentProducts.forEach(p => {
        allProducts.push(p);
    });
    demiProducts.forEach(p => {
        allProducts.push(p);
    });
    if (showCreatives) {
        creativesProducts.forEach(p => {
            allProducts.push(p);
        });
    }
    blondeProducts.forEach(p => {
        allProducts.push(p);
    });
    stylistProducts.forEach(p => {
        allProducts.push(p);
    });
    supportToolsProducts.forEach(p => {
        allProducts.push(p);
    });
    rapidTonerProducts.forEach(p => {
        allProducts.push(p);
    });
    lightenersProducts.forEach(p => {
        allProducts.push(p);
    });

    let title = intl.formatMessage({ id: 'color-list-title' });
    let subtitleText = intl.formatMessage({ id: 'color-list-subtitle' });

    let imageUrl = null;
    let isDarkImage = false;

    if (header && header.colorListHeader) {
        title = header.colorListHeader;
    }
    if (header && header.colorListSubtitle) {
        subtitleText = header.colorListSubtitle;
    }
    if (header && header.colorListBannerImage) {
        imageUrl = get(header, 'colorListBannerImage.localFile.publicURL');
    }
    if (header && header.colorListImageIsDark) {
        isDarkImage = header.colorListImageIsDark;
    }

    let showSlides = true;
    if (currentFilter === Filters.All) {
        showSlides = false;
    }

    return (
        <>
            <Spacing
                removeSpaceTop
                removeSpaceTopMobile
                removeSpaceBottomMobile
            >
                <StPageImageTitleBar url={imageUrl}>
                    <Container>
                        <h1 style={isDarkImage ? { color: '#fff' } : {}}>
                            {title}
                        </h1>
                        <p
                            style={isDarkImage ? { color: '#fff' } : {}}
                            dangerouslySetInnerHTML={{
                                __html: subtitleText,
                            }}
                        ></p>
                    </Container>
                </StPageImageTitleBar>
            </Spacing>

            <Spacing removeSpaceTop removeSpaceBottomMobile>
                <Filter
                    items={[
                        {
                            text: intl.formatMessage({
                                id: 'color-filter-all',
                            }),
                            onClick: () => setCurrentFilter(Filters.All),
                            isActive: currentFilter === Filters.All,
                        },
                        {
                            text: intl.formatMessage({
                                id: 'color-filter-permanent',
                            }),
                            onClick: () => setCurrentFilter(Filters.Permanent),
                            isActive: currentFilter === Filters.Permanent,
                        },
                        {
                            text: intl.formatMessage({
                                id: 'color-filter-demi-permanent',
                            }),
                            onClick: () => setCurrentFilter(Filters.Demi),
                            isActive: currentFilter === Filters.Demi,
                        },
                        {
                            text: intl.formatMessage({
                                id: 'color-filter-creatives',
                            }),
                            onClick: () => setCurrentFilter(Filters.Creatives),
                            isActive: currentFilter === Filters.Creatives,
                        },
                        // UNCOMMENT TO SHOW SEMI_PERM ON MENU
                        // {
                        //   text: 'SEMI-PERMANENT',
                        //   onClick: () => setCurrentFilter(Filters.Semi),
                        //   isActive: currentFilter === Filters.Semi,
                        // },
                        {
                            text: intl.formatMessage({
                                id: 'color-filter-simply-blonde',
                            }),
                            onClick: () => setCurrentFilter(Filters.Blonde),
                            isActive: currentFilter === Filters.Blonde,
                        },
                        {
                            text: intl.formatMessage({
                                id: 'color-filter-studio-stylist',
                            }),
                            onClick: () =>
                                setCurrentFilter(Filters.StudioStylist),
                            isActive: currentFilter === Filters.StudioStylist,
                        },
                        {
                            text: intl.formatMessage({
                                id: 'color-filter-support-tools',
                            }),
                            onClick: () =>
                                setCurrentFilter(Filters.SupportTools),
                            isActive: currentFilter === Filters.SupportTools,
                        },
                        {
                            text: intl.formatMessage({
                                id: 'color-filter-rapid-toners',
                            }),
                            onClick: () =>
                                setCurrentFilter(Filters.RapidToners),
                            isActive: currentFilter === Filters.RapidToners,
                        },
                        {
                            text: intl.formatMessage({
                                id: 'color-filter-lighteners',
                            }),
                            onClick: () => setCurrentFilter(Filters.Lighteners),
                            isActive: currentFilter === Filters.Lighteners,
                        },
                    ]}
                    mobileText={intl.formatMessage({ id: 'filter-text' })}
                />
            </Spacing>

            <Spacing removeSpaceTop removeSpaceBottom>
                {currentFilter === Filters.All && (
                    <ProductGrid
                        categoryImage={getContentfulCategory(
                            ShopifyCollectionHandles.AllProducts,
                            contentfulCategories,
                            intl
                        )}
                        items={allProducts.map(color => {
                            return getColorSwatch(color, colors, intl);
                        })}
                        showSlides={true}
                        showButton={false}
                        isLoggedIn={auth.isLoggedIn}
                        addToCart={auth.addToCart}
                    />
                )}
                {currentFilter === Filters.Permanent && (
                    <ProductGrid
                        categoryImage={getContentfulCategory(
                            ShopifyCollectionHandles.Permanent,
                            contentfulCategories,
                            intl
                        )}
                        items={permanentProducts.map(color => {
                            return getColorSwatch(color, colors, intl);
                        })}
                        showSlides={showSlides}
                        showButton={false}
                        isLoggedIn={auth.isLoggedIn}
                        addToCart={auth.addToCart}
                    />
                )}
                {currentFilter === Filters.Demi && (
                    <ProductGrid
                        categoryImage={getContentfulCategory(
                            ShopifyCollectionHandles.Demi,
                            contentfulCategories,
                            intl
                        )}
                        items={demiProducts.map(color => {
                            return getColorSwatch(color, colors, intl);
                        })}
                        showSlides={showSlides}
                        showButton={false}
                        isLoggedIn={auth.isLoggedIn}
                        addToCart={auth.addToCart}
                    />
                )}
                {currentFilter === Filters.Creatives && showCreatives && (
                    <ProductGrid
                        categoryImage={getContentfulCategory(
                            ShopifyCollectionHandles.Creatives,
                            contentfulCategories,
                            intl
                        )}
                        items={creativesProducts.map(color => {
                            return getColorSwatch(color, colors, intl);
                        })}
                        showSlides={showSlides}
                        showButton={false}
                        isLoggedIn={auth.isLoggedIn}
                        addToCart={auth.addToCart}
                    />
                )}
                {currentFilter === Filters.Blonde && (
                    <ProductGrid
                        categoryImage={getContentfulCategory(
                            ShopifyCollectionHandles.Blonde,
                            contentfulCategories,
                            intl
                        )}
                        items={blondeProducts.map(color => {
                            return getColorSwatch(color, colors, intl);
                        })}
                        showSlides={showSlides}
                        showButton={false}
                        isLoggedIn={auth.isLoggedIn}
                        addToCart={auth.addToCart}
                    />
                )}
                {currentFilter === Filters.StudioStylist && (
                    <ProductGrid
                        categoryImage={getContentfulCategory(
                            ShopifyCollectionHandles.StudioStylist,
                            contentfulCategories,
                            intl
                        )}
                        items={stylistProducts.map(color => {
                            return getColorSwatch(color, colors, intl);
                        })}
                        showSlides={showSlides}
                        showButton={false}
                        isLoggedIn={auth.isLoggedIn}
                        addToCart={auth.addToCart}
                    />
                )}
                {currentFilter === Filters.SupportTools && (
                    <ProductGrid
                        categoryImage={getContentfulCategory(
                            ShopifyCollectionHandles.SupportTools,
                            contentfulCategories,
                            intl
                        )}
                        items={supportToolsProducts.map(color => {
                            return getColorSwatch(color, colors, intl);
                        })}
                        showSlides={showSlides}
                        showButton={false}
                        isLoggedIn={auth.isLoggedIn}
                        addToCart={auth.addToCart}
                    />
                )}
                {currentFilter === Filters.RapidToners && (
                    <ProductGrid
                        categoryImage={getContentfulCategory(
                            ShopifyCollectionHandles.RapidToners,
                            contentfulCategories,
                            intl
                        )}
                        items={rapidTonerProducts.map(color => {
                            return getColorSwatch(color, colors, intl);
                        })}
                        showSlides={showSlides}
                        showButton={false}
                        isLoggedIn={auth.isLoggedIn}
                        addToCart={auth.addToCart}
                    />
                )}
                {currentFilter === Filters.Lighteners && (
                    <ProductGrid
                        categoryImage={getContentfulCategory(
                            ShopifyCollectionHandles.Lighteners,
                            contentfulCategories,
                            intl
                        )}
                        items={lightenersProducts.map(color => {
                            return getColorSwatch(color, colors, intl);
                        })}
                        showSlides={showSlides}
                        showButton={false}
                        isLoggedIn={auth.isLoggedIn}
                        addToCart={auth.addToCart}
                    />
                )}
            </Spacing>
        </>
    );
}
